#overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: auto;
}

#overlay .overlay-body {
  position: absolute;
  width: 80%;
  margin: 3% 10%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,1);
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 20px;
}

#overlay .video-overlay-body {
  width: 45%;
  height: auto;
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%) 
}

#overlay .video-overlay-body .close-img {
  position: relative;
  top: 0vw;
  right: 0vw;
  left: 0vw
}

#overlay .close-img {
  position: fixed;
  top: 4.525VW;
  right: 9.770833333333333VW;
  width: 20px;
  height: 20px;
  z-index: 2;
  background-image: url('/assets/images/sprint_aroma_icons.svg');
  background-repeat: no-repeat;
  background-position: -210px -20px;
  background-size: 600px 80px;
  cursor: pointer;
}

#booking-container,
#membership-container,
#onlinegift-container {
  width: 63VW;
  min-height: 600px;
  height: 600px;
}

#overlay .wrapper {
  width: 100%;
  padding:30px 0;
  margin: 0;
}

/* Form  container css*/
#overlay .form-container {
  display: flex;
  justify-content: space-between;
  /* padding-top: 1.3020833333333335VW */
}
#overlay .booking-form {
   padding-top: 1.3020833333333335VW
}
#overlay .form-container .left-container, 
#overlay .form-container .right-container {
  width:48%;
}

#overlay .form-container .left-container {
  padding-right: 5.520833333333333VW;
}

#overlay .input-container {
  margin-bottom: 1.3020833333333335VW;
}

#overlay .input-container .input-groups {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
}

#overlay .input-container .input-groups .field-caption {
  text-align: left;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  opacity: 0.6;
  vertical-align: middle;
  margin-bottom: 0px;
  display:inline-block;
  white-space: nowrap;
}

#overlay .input-container .input-groups .form-field.error { border:1px solid red; }
/* #overlay .input-container .input-groups .input-rupee-left .form-fielderror { border: 1px solid red; }  */

#overlay .input-container .input-groups .form-field {
  /* width: 350px; */
  width: 18.229166666666664VW;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0,0,0,0.6);
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  background-color: #ffffff;
  padding: 10px 10px;
}

/* custom Radio fields*/
/* The container */
#overlay .input-container.radio-sec { width: 50%; }

#overlay .radio-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left:25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000000;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
}

/* Hide the browser's default radio button */
#overlay .radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
#overlay .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border:1px solid #e3e3e3;
  background-color: #ffffff;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
#overlay .radio-container:hover input ~ .checkmark {
  background-color: #FFFFFF;
}

/* When the radio button is checked, add a blue background */
#overlay .radio-container input:checked ~ .checkmark {}

/* Create the indicator (the dot/circle - hidden when not checked) */
#overlay .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
#overlay .radio-container input:checked ~ .checkmark:after {
  display: block;
  background-color: #D5B981;
}

/* Style the indicator (dot/circle) */
#overlay .radio-container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}
/*end*/

#overlay .radio-field {
  margin:0px 0px 20px 0px;
}

#overlay .radio-field .field-caption {
  width:30% ;
}

#overlay .radio-field .form-field {
  width:auto !important;
  margin:0px 10px;
}

#overlay .radio-field .radio-input-section {
  width:35%;
  display: inline-block;
}

#overlay .radio-field .radio-input-section label:first-child { margin-right: 3.18125VW; }

/*textarea Section*/
#overlay .textarea-sec {
  resize: none;
}

#overlay .textarea-sec .field-caption {
  width:17% !important;
}

#overlay .textarea-sec .input-textarea {
  text-align: left !important;
  display: flex;
}

#overlay .textarea-sec textarea {
  width: 50VW;
  border: 1px solid #e3e3e3;
  min-height: 80px;
  color: rgba(0,0,0,0.6);
  padding: 10px 10px;
}

/*Submit form section*/
#overlay .form-submit-section {
  text-align: center;
  margin: 35px 0px;
}

#overlay .outer-btn {
  display: inline-block;
  border: 1px solid #D6B981;
  padding: 2px;
  height: 40px;	
  width: 142px;
  cursor: pointer;
  /* margin-top: 20px;  */
}

#overlay .form-submit-section .btn {
  width: 100%;
  height: 100%;
  background-color: #D6B981;
  color: #FFFFFF;
  font-family: OpenSans-Bold;
  text-transform: uppercase;
  font-size: 13px;	
  letter-spacing: 2px;	
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;  
  transition: all 0.4s ease-in-out;
}

#overlay .form-submit-section .btn:hover {
  background-color: transparent;
  color: #D6B981;
}
/*End Form  container css*/


/*Drop down customization css*/
#overlay .k-dropdown{
  /* width:57%; */
  /* width: 362px */
  width: 19.7625VW;
}

#overlay .k-select .k-icon{
  opacity: 0;
}

/* .k-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.k-list .k-item{
  border:none !important;
  text-transform: capitalize !important;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-list .k-item:hover{
  color: #000000 !important;
  background-color: #D6B981 !important;
  opacity: 0.9;
}
.k-list .k-item.k-state-selected, .k-list-optionlabel.k-state-selected, .k-list-container .k-button:active, .k-list-container .k-button.k-state-active, .k-column-menu .k-menu:not(.k-context-menu) .k-item.k-state-selected, .k-spreadsheet-popup .k-button:active, .k-spreadsheet-popup .k-button.k-state-active, .k-spreadsheet-popup .k-button.k-state-selected, .k-menu-group .k-item.k-state-selected, .k-menu.k-context-menu .k-item.k-state-selected {
  background-color: #D6B981!important;
  color: #000000;
  opacity: 0.9;
}
#overlay .k-select {
  background-image: url("/assets/images/ic_drop down-grey.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
}

#overlay .k-dropdown {
  border :1px solid #e3e3e3;
}

#overlay .k-dropdown.error {
  border: 1px solid red;
}

#overlay .k-dropdown .k-dropdown-wrap {
  border:none;
  /* background-color: #D6B981; */
  background-image: none;
  background:none;
  padding:5px 0px;
}

#overlay .k-dropdown .k-dropdown-wrap.k-state-focused,
#overlay .k-dropdown .k-dropdown-wrap:hover, 
#overlay .k-dropdown .k-dropdown-wrap.k-state-hover {
  background-color: #FFFFFF;
  background-image: none;
  background:none;
  padding:5px 0px;
  box-shadow: none !important;
}

#overlay .k-dropdown-wrap .k-input {
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  background-color: #ffffff;
  opacity: 0.6;
} 

/* .k-animation-container div {
  background-color: #ffffff !important;
} */

.k-animation-container {
  z-index: 99 !important;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  /* background-color: #ffffff; */
}

#overlay .k-animation-container .k-state-selected {
  background-color: #D6B981 !important;
}

#overlay hr {
  /* margin-right:5%; */
  border:1px solid #D6B981;
}
/* form and overlay container*/
