@media screen and (min-width: 320px) and (max-width: 767px) {
  #overlay .close-img { top: 20px !important; right: 20px !important; }
  #overlay .form-container { display: block; }
  #overlay .form-container .left-container,
  #overlay .form-container .right-container { 
    padding-right: 0;
    width: 100%;
    margin: auto;
  }
  
  #overlay .input-container .input-groups .form-field,
  #overlay .react-datepicker__input-container input { width: 165px !important; }
  #overlay .k-dropdown { width: 187px !important }

  #overlay .input-container.radio-sec { width: 100%!important; }

  #overlay .textarea-sec textarea {width: 42VW !important}

  #overlay .overlay-body { width: 100% !important; margin: auto !important; padding: 30px 0 0; }

  #overlay .video-overlay-body { width: 80% !important;  }

  #overlay .video-overlay-body .close-img { top: 0 !important; right: 0 !important; }
  
  #booking-container, #membership-container, #onlinegift-container,#franchise-container { width: 100%; padding: 0 20px; }

  #overlay .radio-field .radio-input-section { width: 50% !important; }

  .form-control { width: 25.229167VW;}
  #overlay .input-container .input-groups { display: flex !important; }

  #overlay .input-container .input-groups .field-caption { white-space: unset; }

  .overlay-heading p { width: 90%;}
  #booking-container .tot-book-price { padding-bottom: 30px; }
  #overlay .wrapper { max-width: 350px; padding: 30px 0 0; margin: 0 auto; }
  #booking-container .person-heading { margin: 20px 0; }
  #membership-container .page-note-container { padding: 20px 0; }
  #onlinegift-container .recipientblock { padding-top: 0; }
  #overlay .input-container .input-groups .amount-price { width: 147px !important; }
  #onlinegift-container .input-rupee-left::before { left: 10px; }
  #onlinegift-container .plan-outer { margin: 30px 0px; display: block; }
  #onlinegift-container .gift-card-container { display: block; margin-bottom: 40px; }
  #onlinegift-container .gift-card-container .img-thumbnail { width: 100%; height: auto; }
  #booking-container .mem-card { width: 165px !important; }
  #booking-container .price { width: 180px; }
  .rdtOpen .rdtPicker { right: 0 !important; }
  #booking-container .show-to-pc { display: none; }
  #booking-container .show-to-mob { display: block; }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #overlay hr { width: 75%; margin: 0 auto; }
  #overlay .close-img { top: 50px; right: 70px; }
  #overlay .video-overlay-body .close-img { top: 0 !important; right: 0 !important; }
  #overlay .wrapper { padding: 50px 0 0; }
  #overlay .form-container { display: block; }
  #overlay .form-container .left-container,
  #overlay .form-container .right-container { 
    padding-right: 0;
    width: 75%;
    margin: auto;
  }
  #onlinegift-container .input-rupee-left::before { left: 10px; }
  #overlay .input-container .input-groups .form-field,
  #overlay .react-datepicker__input-container input { width: 200px !important; }
  #overlay .input-container .input-groups .amount-price { padding: 12px 0 12px 20px !important; }
  #overlay .k-dropdown { width: 222px !important; }
  #overlay .textarea-sec .input-textarea, 
  #overlay .input-container.radio-sec { width: 75%; margin: 0 auto; }
  #overlay .textarea-sec textarea {width: 200px}
  #booking-container, #membership-container, #onlinegift-container { width: 700px; }
  #booking-container .add-service-sec,
  #booking-container .tot-book-price,
  #booking-container .person-heading { width: 75%; margin: 0 auto; }
  #booking-container .person-heading hr { margin-bottom: 20px; }
  #booking-container .price { width: 200px; }
  #booking-container .dynamic-service-sec { margin-bottom: 30px; }
  #booking-container .service-container { margin-top: 20px; }
  #onlinegift-container .form-field-price { padding: 10px 12px 10px 0 !important; }
}

@media screen and (min-width: 1600px)  {
    
  #overlay .overlay-body { 
    /* width: 65%; */
    /* margin: 8% auto; */
    align-items: center
  }
  
  /* #overlay .booking-form { padding:0.302083VW 5.208333333333334VW;  } */

  /* #overlay .close-img { top :9.525VW; right: 18.770833VW; } */
 
  #overlay .wrapper { width : 90%; margin: 0 auto; }
  #membership-container { padding:0.302083VW 3.208333333333334VW; }
  #booking-container,
  #membership-container,
  #onlinegift-container {
    /* width: 63VW; */
    /* min-height: 34.25VW; */
    height: 34.25VW;
  }

  #places-container { 
    /* width: 60VW; */
    min-height: 27.25VW;
    height: 27.25VW;
  }
}
