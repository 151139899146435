/*  Font styles - Start */
@font-face { font-family: Prata-Regular; src: url(/assets/fonts/Prata/Prata-Regular.ttf) format('truetype'); }
@font-face { font-family: OpenSans-Bold; src: url(/assets/fonts/OpenSans/OpenSans-Bold.ttf) format('truetype'); }
@font-face { font-family: OpenSans-BoldItalic; src: url(/assets/fonts/OpenSans/OpenSans-BoldItalic.ttf) format('truetype'); }
@font-face { font-family: OpenSans-ExtraBold; src: url(/assets/fonts/OpenSans/OpenSans-ExtraBold.ttf) format('truetype'); }
@font-face { font-family: OpenSans-ExtraBoldItalic; src: url(/assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf) format('truetype'); }
@font-face { font-family: OpenSans-Italic; src: url(/assets/fonts/OpenSans/OpenSans-Italic.ttf) format('truetype'); }
@font-face { font-family: OpenSans-Light; src: url(/assets/fonts/OpenSans/OpenSans-Light.ttf) format('truetype'); }
@font-face { font-family: OpenSans-LightItalic; src: url(/assets/fonts/OpenSans/OpenSans-LightItalic.ttf) format('truetype'); }
@font-face { font-family: OpenSans-Regular; src: url(/assets/fonts/OpenSans/OpenSans-Regular.ttf) format('truetype'); }
@font-face { font-family: OpenSans-SemiBold; src: url(/assets/fonts/OpenSans/OpenSans-SemiBold.ttf) format('truetype'); }
@font-face { font-family: OpenSans-SemiBoldItalic; src: url(/assets/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf) format('truetype'); }
/*  Font styles - End */

/********************************************************** scroll bar - start*/
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background:rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:rgba(0, 0, 0, 0.2);
}
/********************************************************** scroll bar - end*/

body {
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  font: inherit;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
}

ol, ul { list-style: none; margin: 0; }

a { text-decoration: none; background-color: transparent; }

a:hover { text-decoration: none; cursor: pointer; }

textarea:focus, input:focus { outline: none; }

.cls-err-msg {
  color: red;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
  color: red;
  opacity: 0.6;
  margin-top: 10px;
}
.send-noti {
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px; 
  text-align: left;
  margin: 1.5625VW auto 0;
}

.pre-suc-response, .suc-response { 
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
  opacity: 0.6;
  text-align: center; 
}

.suc-response { color: green; }

.pre-suc-response { color: #000; }

.fail-response { color: red; }

.disablebtn { pointer-events: none; opacity: 0.5; }

.ic-map {
  background: url('/assets/images/sprint_aroma_icons.svg') no-repeat -515px -14px;
  width: 12px;
  height: 16px;
}

.ic-cup {
  /* background: url('/assets/images/sprint_aroma.svg') no-repeat -15px -15px;
  width: 36px;
  height: 38px; */
  background: url('/assets/images/ic_wellness dwells.svg') no-repeat;
  width: 53px;
  height: 42px;
  margin: 1px auto;
}

.ic-spray {
  /* background: url('/assets/images/sprint_aroma.svg') no-repeat -65px -14px;
  width: 40px;
  height: 40px; */
  background: url('/assets/images/ic_enjoy the difference.svg') no-repeat;
  width: 40px;
  height: 40px;
  margin: 1px auto;
}

.ic-location {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -118px -14px;
  width: 40px;
  height: 40px;
  margin: 1px auto;
}

.ic-contact {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -172px -15px;
  width: 36px;
  height: 38px;
  margin: 1px auto;
}

.ic-spa-review {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -223px -15px;
  width: 42px;
  height: 38px;
  margin: 75px auto 0;
}

.ic-ser-1 {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -505px -14px;
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.ic-ser-2 {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -558px -14px;
  width: 41px;
  height: 40px;
  margin: 0 auto;
}

.ic-ser-3 {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -614px -14px;
  width: 38px;
  height: 40px;
  margin: 0 auto;
}

.ic-ser-4 {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -666px -14px;
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.ic-ser-5 {
  /* background: url('/assets/images/ic_therapy1.png'); */
  background: url('/assets/images/ic_thai-hospitality.png');
  width: 205px;
  height: 165px;
  margin: 0 auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.ic-ser-6 {
  background: url('/assets/images/ic_therapy2.png');
  width: 205px;
  height: 165px;
  margin: 0 auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.ic-ser-7 {
  background: url('/assets/images/ic_therapy3.png');
  width: 205px;
  height: 165px;
  margin: 0 auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.ic-ccav-success {
  background: url('/assets/images/ic_ccavenue_success.png');
  width: 150px;
  height: 150px;
  margin: 0 auto;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}

.ic-ccav-error {
  background: url('/assets/images/ic_ccavenue_error.png');
  width: 150px;
  height: 150px;
  margin: 0 auto;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}

.ic-loc-1 {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -720px -14px;
  width: 53px;
  height: 40px;
  margin: 0 auto;  
}
.ic-loc-2 {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -788px -15px;
  width: 53px;
  height: 38px;
  margin: 0 auto;
}
.ic-loc-3 {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -855px -15px;
  width: 71px;
  height: 39px;
  margin: 0 auto;
}
.ic-loc-4 {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -940px -14px;
  width: 53px;
  height: 40px;
  margin: 0 auto;
}
.ic-loc-5 {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -1008px -12px;
  width: 53px;
  height: 44px;
  margin: 0 auto;
}
.ic-loc-6 {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -1076px -19px;
  width: 69px;
  height: 32px;
  margin: 0 auto;
}
/* .ic-loc-7 {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -1453px -3px;
	width: 42px;
	height: 40px;
  margin: 0 auto;
} */

.ic-ser-body {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -1160px -14px;
  width: 68px;
  height: 60px;
  margin: 0 auto;
}

.ic-ser-foot {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -1241px -14px;
  width: 62px;
  height: 60px;
  margin: 0 auto;
}

.ic-ser-head {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -1318px -15px;
  width: 61px;
  height: 58px;
  margin: 0 auto;
}

.ic-ser-manicure {
  background: url('/assets/images/sprint_aroma.svg') no-repeat -1394px -15px;
  width: 59px;
  height: 58px;
  margin: 0 auto;
}

.ic-bag {
	background: url('/assets/images/sprint_aroma.svg') no-repeat -379px -14px;
	width: 43px;
  height: 40px;
  margin: 0 auto;
}

.up-arow-icon {
	background: url('/assets/images/sprint_aroma_icons.svg') no-repeat -15px -15px;
	width: 48px;
	height: 48px;
  position: absolute;
  right: 5.052083333333333VW;
  /* bottom: 1.5625VW;    */
  cursor: pointer;
}

.price-table-icon {
	background: url('/assets/images/sprint_aroma.svg') no-repeat -279px -14px;
	width: 29px;
	height: 40px;
}
