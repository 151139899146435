@media screen and (min-width: 320px) and (max-width: 767px) {
  #carousel .desc-title { height: auto; line-height: 32px; font-size: 24px }
  #carousel .desc-content { font-size: 14px;  width: 300px; } 
  /* #carousel .carousel-block { height: 100vh; } */
  #carousel .circle-row { bottom: 20px; }
  #carousel .carousel-box img { object-position: left; }
}


/* Tab media query */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #carousel .circle-row { bottom: 50px; }
  #carousel .carousel-box img { object-position: right; }
}