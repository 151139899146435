/*Overlay heading css*/
.overlay-heading {
  text-align: center;
  margin-bottom : 50px;
  margin-top: 50px;
  
}

.overlay-heading .header-img-section {
  /* padding: 5px 50px; */
  /* border-bottom: 1px solid #D6B981; */
  display: inline-block;
  /* margin-bottom: 20px; */
}

.overlay-heading .header-img-section .header-img {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-image: url("/assets/images/sprint_aroma.svg");
  background-repeat: no-repeat;
}

.overlay-heading .ic-divider {
  height: 1px;
  width: 200px;
  background: #D6B981;
  margin: 15px auto 0 ;
}


.overlay-heading .header-img-section .header-img.booking-register {
    background-position: -318px -7px;
}
.overlay-heading .header-img-section .header-img.online-gift{
  background-position: -374px -7px;
}

.overlay-heading .header-img-section .header-img.membership-details {
  background-position: -376px -10px;
}

.overlay-heading .header-img-section .header-img.our-location {
  background-position: -113px -8px;
}

.overlay-heading h4 {
  font-family: Prata-Regular;
  font-size: 22px;
  letter-spacing: 4px;
  line-height: 36px;
  margin-bottom: 20px;
  margin-top: 1.5625VW;
}

.overlay-heading p,.overlay-heading .overdesc {
  font-family: OpenSans-Regular;
  font-size: 16px;
  line-height: 24px;
  /* width: 70%; */
  margin: 0 auto;
  color: rgba(23, 23, 23,0.5);
}


