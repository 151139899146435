/*Booking overlay Css*/
#franchise-container {
  width: 67VW;
  min-height: 600px;
  height: 600px;
}

#franchise-container .input-container .input-groups { width: 31vw!important } 

#franchise-container .field-dropdown { width: 55% }

#franchise-container .field-input { width: 55% !important}

#franchise-container .stl-normal { text-transform: none; }

#franchise-container .overlay-heading p { width: 70%; }

#franchise-containe .input-container .input-groups .field-caption { width:30% }

#franchise-container .input-container .input-groups .form-field { width: calc(100% - 22px ) !important; }

#franchise-container .k-dropdown { width: 100% !important; }

#franchise-container .textarea-sec textarea { width: 16.229167VW !important; }

#franchise-container .radio-field { display: flex; }

#franchise-container .input-container.radio-sec { width: 100% !important; }

#franchise-container .radio-field .radio-input-section { width: 16.229167VW !important; padding: 10px 10px !important; }

#franchise-container .radio-field .radio-input-section { width: fit-content;}

#franchise-container .run-franchise .field-caption { width: 40% !important; white-space: normal !important;}
/*radio button dynamic append card field css*/
#franchise-container .membership_card_sec {
  margin-bottom: 20px;
  padding:0px 1%;
}

#franchise-container .run-franchise.radio-container input:checked ~ .checkmark:after {
  display: block;
  background-color: #D5B981;
}

/* Style the indicator (dot/circle) */
#franchise-container .run-franchise .radio-container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

#franchise-container .mem-card { width: 19.229166666666664VW !important; }
/* #franchise-container .membership_card_sec .input-groups{
  display:block !important;
} */
#franchise-container .membership_card_sec .input-groups .field-caption {
  width: 31%;
}
/*radio button dynamic append card field css*/
.membership_card_sec {
  margin-bottom: 20px;
  padding:0px !important;
}
.membership_card_sec .input-groups .field-caption{
  width: 31%;
}


#franchise-container .add-service-btn {
  border: 1px solid #D6B981;
  padding:10px 25px;
  cursor: pointer;
  background: #FFFFFF;
  color: #D6B981;
  font-family: OpenSans-Bold;
  font-size: 14px;
  letter-spacing: 1.75px;
  line-height: 19px;
  text-align: right;
}

#franchise-container .add-service-btn .plus-icon {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url("/assets/images/sprint_aroma_icons.svg");
  background-repeat: no-repeat;
  background-position: -686px -15px;
  margin-top: 3px;
  margin-right: 5px;
}

.form-control {
  width: 18.229166666666664VW;
  border: none;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0,0,0, 0.6);
} 

#franchise-container .show-to-pc { display: block; }
#franchise-container .show-to-mob { display: none; }
@media screen and (min-width: 320px) and (max-width: 767px) {
  #franchise-container .input-container .input-groups {
    width: 100% !important;
}
}
@media screen and (min-width: 767px) and (max-width: 1023px) {
  #franchise-container .input-container .input-groups {
    width: 100% !important;
}
}
/* @media screen and (min-width: 1600px) {
  #franchise-container .k-dropdown { width: 17.5VW !important; }
}

@media screen and (min-width: 1920px) {
  #franchise-container .k-dropdown { width: 17.4VW !important; }
}

@media screen and (min-width: 2000px) {
  #franchise-container .k-dropdown { width: 17.3VW !important; }
} */
