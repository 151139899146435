#footer { 
  /* position: relative; */
  width: 100%;
  /* height: 230px; */
  /* height: 11.979166666666668VW; */
  background-image: url(/assets/images/footer-texture.jpg);
  background-repeat: no-repeat;
  /* background-size: 100% 100%; */
  background-size: cover;
  border-top: 1px solid #D6B981;
  z-index: 1;

}

#footer .footer-row,
#footer .copy-right-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 51px 410px; */
  padding: 2.65625VW 21.354166666666664VW;
  border-bottom: 1px solid #D6B981;
}

#footer .aroma-logo { height: 48px;	width: 184px; }

#footer .copy-right-row .footer-left  {
  font-family: OpenSans-Regular;
  font-size: 14px;
  color: rgba(23, 23, 23,0.5);
  letter-spacing: 0.5px;
  /* opacity: 0.5; */
}

#footer .footer-right,
#footer .menu-row-block,
#footer .social-media-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

#footer .menu-row-block li {
  position: relative;
  margin: 0 1.3020833333333335VW; /* 25px */
  /* white-space: nowrap; */
 }

/* To animate underline while mouse hover - start */
#footer .menu-row-block li:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  background: #D4B880;
  visibility: hidden;
  transform: scaleX(0);
  transition: .25s linear;
 }
 
#footer .menu-row-block li:hover:before,
#footer .menu-row-block li:focus:before {
  width: 100%;
  visibility: visible;
  transform: scaleX(1);
}
/* To animate underline while mouse hover - end */

#footer .menu-row-block li a {
  font-family: OpenSans-SemiBold;
  color: #481D0C;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 19px;
  opacity: 0.6;
  text-transform: uppercase;
}

#footer .menu-row-block li a:hover {
  font-family: OpenSans-SemiBold;
  color: #B6934E;
  opacity: 1;
}

#footer .menu-row-block li:last-child { margin-right: 0; }

#footer .copy-right-row {
  /* padding: 51px 410px; */
  padding: 1.5625VW 18.354166666666664VW;
  border-bottom: 0; 
}

#footer .instagram-logo,
#footer .fb-logo {
  height: 38px;	
  width: 38px; 
  cursor: pointer;
}

#footer .instagram-logo { 
  background: url(/assets/images/sprint_aroma_icons.svg) no-repeat -78px -15px;
  margin-right: 1.0416666666666665VW;  
}

#footer .fb-logo { background: url(/assets/images/sprint_aroma_icons.svg) no-repeat -131px -15px; }

#footer .terms-policy{
  display: flex;
  justify-content: space-between;
}
#footer .terms-services a  {
  margin-right: 50px;
  font-family: OpenSans-Regular;
  font-size: 14px;
  color: rgba(23, 23, 23,0.5);
  letter-spacing: 0.5px;
}
/* #footer .terms-policy{
  font-family: OpenSans-Regular;
  font-size: 14px;
  color: rgba(23, 23, 23,0.5);
  letter-spacing: 0.5px;
} */
#footer.terms-services a:link {
  font-family: OpenSans-Regular;
  font-size: 14px;
  color: rgba(23, 23, 23,0.5);
  letter-spacing: 0.5px;
}

#footer .terms-services a:visited {
  font-family: OpenSans-Regular;
  font-size: 14px;
  color: rgba(23, 23, 23,0.5);
  letter-spacing: 0.5px;
}
