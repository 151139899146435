.switch {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 30px;
    margin: 0 auto;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F9F7F1;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before  {
    position: absolute;
    content: "";
    height: 30px;
    width: 100px;
    left: 0px;
    bottom: 0px;
    background-color: #D6B981;
    -webkit-transition: .4s;
    transition: .4s;
    color:#FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slider .self{
    color: #FFFFFF;
}


input:checked + .slider {
    background-color: #F9F7F1;
}

input:focus + .slider {
    box-shadow: 0 0 1px #F9F7F1;
}

input:checked + .slider:before {
    background-color: #D6B981;
    color:#FFFFFF;
    -webkit-transform: translateX(100px);
    -ms-transform: translateX(100px);
    transform: translateX(100px);
}
input:checked + .slider .gift {
    color: #FFFFFF;
    transition: color .4s;
}
input:checked + .slider .self {
    color: #000000;
    transition: color .4s;
}
    /* Rounded sliders */
.slider.round {
    border-radius: 14px;
}

.slider.round:before {
    border-radius: 14px;
}
.slider span{
    position: relative;
    width: 100px;
}
.slider {
    display:flex;
    justify-content: space-evenly;
    align-items: center;
}

.toogle-btn {
    margin-bottom: 30px;
    font-family: OpenSans-Regular;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
}
