#header { 
  position: fixed;
  width: 100%;
  z-index: 3; 
  transition-duration: .7s;
}

#header .header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.125VW 10.208333333333334VW 0;  
  animation-fill-mode: none !important;
}

#header .header-right,
#header .menu-row-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

#header .aroma-logo {
  /* height: 60px;
  transition: height 0.3s; */
}

#header .menu-row-block li {
  position: relative;
  margin: 0 1.3020833333333335VW; /* 25px */
  white-space: nowrap;
  /* cursor: pointer; */
 }
 #header .menu-row-block li:hover a {
  color: #B6934E;
  opacity: 1;
 }

/* To animate underline while mouse hover - start */
#header .menu-row-block li:before {
  content: "";
  position: absolute;
  width: 100px;
  height: 2px;
  bottom: -2px;
  background: #D4B880;
  visibility: hidden;
  transform: scaleX(0);
  transition: .25s linear;
 }
 
#header .menu-row-block li.list-inactive:hover:before,
#header .menu-row-block li.list-inactive:focus:before {
  width: 100%;
  visibility: visible;
  transform: scaleX(1);
}
/* To animate underline while mouse hover - end */

#header .menu-row-block li a,
#header .menu-row-block li span {
  font-family: OpenSans-SemiBold;
  color: #481D0C;  
  letter-spacing: 2px;
  line-height: 19px;
  opacity: 0.6;
  text-transform: uppercase;
  /* transition:font-size 0.5s; */
}

#header .header-row .menu-row-block li a,
#header .header-row .menu-row-block li span {  
  font-size: 14px;  
}

#header .menu-row-block li a:hover {
  font-family: OpenSans-SemiBold;
  color: #B6934E;
  opacity: 1;
}


#header .header-row  .menu-row-block li:last-child { 
  height: 44px;
  width: 146px;  
}

#header .menu-row-block li:last-child {
  box-sizing: border-box;
  height: 44px;
  width: 146px;
  border: 2px solid #D6B981;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-right: 0;
  cursor: pointer;
}

#header .menu-row-block li:last-child a,
#header .menu-row-block li span {
  color: #B6934E;
  font-family: OpenSans-Bold;
  font-size: 16px;
  letter-spacing: 2.6px;
  line-height: 22px;
  text-align: center;
  padding: 0;
  opacity: 1;
  /* transition:font-size 0.7s linear;  */
}

/* when user click on header menu */
#header .menu-active {
  border-bottom: 2px solid #D4B880 !important;
  font-family: OpenSans-SemiBold;
  color: #B6934E !important;
  opacity: 1 !important;
 }

#header .line1,
#header .line2,
#header .line3 {
  height: 3px;
  background-color:#D6B981;
  margin: 6px 0;
  transition: .4s;
}

#header .line1,
#header .line3 {
  width: 30px; 
}
#header .menu-logo {
  margin: auto;
  padding-left: 60px;
  cursor: pointer;
  z-index: 999999; 
  display: none;
}

#header .line2 { width: 20px; }

#header .menu-container {
  background-color: #ffff;
  position: absolute;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0; 
  z-index: 9;
  /* scroll-behavior: smooth; */
  transform:translateY(-100%);
}

/***************************** minimizing the menu while scrolling down start*/

#header .min-header { 
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.525VW 10.416666666666668VW !important; 
  background-color: #FFFF;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.06);
  /* transition:  padding 0.5s linear; */
  /* -webkit-transition: background-color 1000ms linear; */
}

#header .min-header .aroma-logo { 
  width: 70%;
}

#header .min-header .menu-row-block li a,
#header .min-header .menu-row-block li span {  
  font-size: 12px;  
}

#header .min-header .menu-row-block li:last-child { 
  height: 40px;
  width: 130px;  
}

#header .header-hide {
  display: none;
}

/* #header .aromalogo { height: 40px !important; transition: height 0.8s ease-out; } */

#header .mini-menu-item { font-size: 10px !important;transition:font-size 0.7s linear; }

#header .mini-menu-item span{  height: 14px; width: 14px;}
/***************************** minimizing the menu while scrolling down end*/


/***************************** menu hover animations start when expanded */
#header .menu-title {
  color: #481D0C;
}
#header .menu-item {
  color: #481D0C;
  font-family: GreycliffCF-Heavy;
  font-size: 30px;
  line-height: 3.604166666666667VW;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  display:flex;
  margin: 30px;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  animation: TitleCollapse 0.3s;
}

#header .menu-item::after {
  content: '';
  position: absolute;
  width: 95%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0px;
  left: 0;
  background-color: #481D0C;
  transform-origin: bottom center;
  transition: transform 0.6s ease-out;
}

#header .menu-item:hover::after {
  transform: scaleX(1);
  transform-origin: bottom center;
}

/* #header .menu-item:hover { animation: TitleExpand 0.8s ease-out forwards; } */

/* @keyframes TitleExpand {
  0% { letter-spacing: 0px; }
  100% { letter-spacing: 20px; }
}

@keyframes TitleCollapse {
  0% { letter-spacing: 20px; }
  100% { letter-spacing: 0px; }
} */

/***************************** menu hover animations end */

#header .menu-content { margin: auto; }

#header .hdr-loc-block {
  position: absolute;
  right: 3.4895833333333335VW;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#header .ic-map-title {
  font-family: OpenSans-SemiBold;
  font-size: 14px;
  color: #000000;
  letter-spacing: 1px;
  line-height: 19px;
  padding-left: 5px;
  opacity: 0.5;
  text-align: right;
  text-transform: uppercase;
}

#header .header-row .menu-row-block li:last-child { cursor: pointer; }
