
#carousel .carousel-block {
  position: relative;
  overflow: hidden;
}

#carousel .slideshow-container {
  position: relative;
  margin: auto;
}

#carousel .carousel-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: rgba(0,0,0,0.06350);
}

#carousel .image-container {
  width: 100%;
  height: 100vh;
  object-fit: cover;  
}

#carousel .carousel-box img {
  width: 100%;
  height: 100vh;
  position: absolute;
  object-fit: cover;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity:0;
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  /* display: none; */
}

#carousel .opaque {
  opacity:1 !important;
  /* display: block; */
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=1);
}

#carousel .desc-block { position: absolute; }

#carousel .desc-title { 
  font-family: Prata-Regular;
  font-size: 42px;
  color: #000000;
  letter-spacing: 10px;
  line-height: 78px;
  text-align: center;
  text-transform: uppercase;
  height: 100px;
}

#carousel .desc-content { 
  font-family: OpenSans-Light;
  font-size: 20px;
  color: rgba(0,0,0,0.7);
  letter-spacing: 0.5px;
  line-height: 28px;
  text-align: center;
  width: 635px;
  margin: 18px auto 0;
  height: 100px;
}

#carousel .book-now-btn-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 186px;
  border: 1px solid #D6B981;
  margin: 50px auto 0;
  padding: 3px;
  cursor: pointer;
}

#carousel .book-now-btn-inner { 
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  font-family: OpenSans-Bold;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 22px;
  text-transform: uppercase;
  background-color: #D6B981;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;
}

#carousel .book-now-btn-inner:hover {
  background-color: transparent;
  color: #D6B981;
}

#carousel .circle-row { 
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  /* bottom: 50px; */
  bottom: 2.604166666666667VW;
  text-align: center;
 }

#carousel .circle-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 10px;
  border: 1px solid #D6B981;
  border-radius: 100%;
  margin: 0 4px;
}

#carousel .circle {
  /* height: 9px;
  width: 9px; */
  cursor: pointer;
  border-radius: 100%;
  padding: 3px;
  transition: background-color 0.4s ease;
  cursor: pointer;
  box-sizing: content-box; 
}

#carousel .circle:hover, .active { background-color: #D6B981; }

/* Carousel fading animation - start */
/* #carousel .fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 5.5s;
  animation-name: fade;
  animation-duration: 5.5s;
} */
/* @-webkit-keyframes fade { from {opacity: .3} to {opacity: 1} }
@keyframes fade { from {opacity: .3} to {opacity: 1} } */
/* Carousel fading animation - end */

