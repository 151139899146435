/* Mobile resppnsive */
@media screen and (min-width: 320px) and (max-width: 767px) {
  #footer .copy-right-row { padding: 0; display: block; text-align: center; }
  .up-arow-icon { bottom: 22px; }
  #footer .copy-right-row .footer-left { padding: 10px 0 5px; }
  #footer .social-media-block { padding: 5px 0 15px; }
  #footer .terms-policy {display: block; text-align: center;}
  #footer .footer-right{margin-right: 12px;}
  /* #footer ul.terms-policy { padding: 0 !important;} */
  #footer .instagram-logo {margin-right: 5.041667VW !important;}
}

/* Tablet/IPad resppnsive */
@media screen and (min-width: 767px) and (max-width: 1024px) {
  #footer .copy-right-row { padding: 10px 140px 10px 40px; }
  #footer .up-arow-icon { right: 40px; bottom: 5px; }
  #footer .social-media-block { padding: 0 !important; }
  #footer .terms-policy { padding: 0 !important;}
  #footer .terms-services a {margin-right: 10px;}
}