/* Mobile Resppnsive */
@media screen and (min-width: 320px) and (max-width: 767px) {
  #terms .menu-blk { width: 90%; margin: 30px auto 50px; }
  #terms .tc { margin-top: 50px; }
}

/* Tablet/IPad responsive */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #terms .tc { margin-top: 50px; }
  #terms .menu-blk { width: 90%; margin: 30px auto 50px; }
}


