#Addon {
    width: 100%;
  }
  #Addon .tc {
    text-align: center;
    font-family: Prata-Regular;
    font-size: 22px;
    letter-spacing: 4px;
    line-height: 36px;
   
    margin-top: 1.5625VW;
  }
  
  #Addon .menu-blk {
    width: 70%;
    margin: 20px auto;
  }

  #Addon .contact {
    text-align: center;
    font-family: Prata-Regular;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 36px;
    margin-top: 20px;
  }
  
  #Addon li {
    font-family: OpenSans-Light;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0.5px;
    line-height: 26px;
    /* text-align: center; */
    margin-top: 20px;
  }
  
  #Addon .menu-blk ul {
    list-style: initial;
  }