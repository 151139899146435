/* #blogpage{
	position: fixed;
	user-select: none;
	height: 100%;
	right: 0px;
	left: 0px;
	overflow-y: scroll;
} */

#blogpage .title {
    margin: 0 auto;
    /* width: fit-content; */
    text-align: center;
}

#blogpage .title-1,
#blogpage .title-2 {
    font-size: 50px;
    letter-spacing: 2px;
    line-height: 66px;  
}

#blogpage .title-1 {
    color:   #333333;
    font-family: GreycliffCF-DemiBold;
    padding-right: 10px;  
}

#blogpage .title-2 {
    color: #00D2BB;
    font-family: blueliquidbold-webfont;
}

#blogpage .blog-desc div{
  color: #3B3B3B;
  font-family: GreycliffCF-DemiBold;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}
#blogpage .article-block {
  padding: 1.925VW 13.208333333333334VW 0;
  min-height: 350px;
  
}

#blogpage .blog-title {
  color: #000000;
  font-family: GreycliffCF-Bold;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: left;
}

#blogpage  .ban-head {
    padding: 1.925VW 13.208333333333334VW 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

#blogpage .content-body::-webkit-scrollbar{
  width: 6px;
  height: 10px;
  max-height: 20px;
}

#blogpage .content-body::-webkit-scrollbar-thumb {
  background: transparent; 
  border-radius: 20px;
  height: 10px;
  max-height: 20px;
}

#blogpage .content-body:hover::-webkit-scrollbar-thumb {
  /* background: #888; */
  /* width: 8px; */
  border-radius: 4px;
  background: rgba(0,0,0,0.3);
}

#blogpage .banner-block {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#blogpage .back-img {
  /* position: absolute;
  left: -15px; */
  transition: all .5s;
}

#blogpage .back-button-block:hover .back-img {
  transform: translateX(-10px);
 }

#blogpage .banner-desc {
	position: absolute;
	
}

#blogpage .banner-header {
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
  margin-top: 15px;
  position: absolute;
  top: 0;
  width: 100%;
}


#blogpage .blog-list {
  /* padding:16.925VW 13.208333333333334VW 19vw; */
  padding: 9.925VW 13.208333333333334VW 26vw;
  font-family: OpenSans-Light;
  
}

#blogpage .back-button-block {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#blogpage .back-button {
	background-color: transparent;
	color: #FBFCFB;
	padding: 10px;
	font-family: GreycliffCF-Bold;
	cursor: pointer;
}

#blogpage .free-trial {
	padding: 14px 20px;
  opacity: 0.9;	
  background-color: transparent;
  font-family: GreycliffCF-Bold;
  display: flex;
	align-items: center;
	color: #FFFFFF;
  justify-content: center;
	cursor: pointer;
	border: 1px solid #FFFFFF;
  box-shadow: 0 2px 25px 0 rgba(0,0,0,0.1);
  transition: all .5s;
}

#blogpage .kiri-yoga-logo {
  position: absolute;
  top: 10px;
  left: 160px;
}

#blogpage .free-trial:hover {
  color: #000;
  background-color: #FFFFFF; 
}

#blogpage .banner-img { width: 100%; }

#blogpage .banner-title {
	width: 800px;
	margin: auto;
	margin-top: 50px;
}

#blogpage .social-links {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}

#blogpage .divider {
  height: 1px;
  width: 1080px;
  opacity: 0.1;
  background-color: #000000;
  margin: 1.925VW 13.208333333333334VW 0;
}

#blogpage .sub-head {
    color: #FFFFFF;
    font-family: blueliquidbold-webfont;
    font-size: 20px;
    letter-spacing: 4.19px;
    line-height: 66px;
    text-align: center;
    text-transform: capitalize;
  }
  
  #blogpage .title-desc {
      width: 100%;	
      text-align: center;
      color: #FFFFFF;	
      font-size: 20px;
      font-family: GreycliffCF-Regular;
      line-height: 28px;
  }
  
  #blogpage .content-body {
      overflow-y: scroll;
      max-height: 600px;
      min-height: 600px;
    display: block;
    margin: 40px 12.208333333333334VW 60px;
    position: relative;
  }
  
  #blogpage .service-block {
      padding: 20px;
    display: flex;
    justify-content: center;
  }
  
  #blogpage .service-header {	
    padding-bottom: 10px;
    padding-top: 10px;
    /* display: flex; */
    /* justify-content: space-between; */
  }
  
  #blogpage .service-name {
    font-family: OpenSans-Light;
    color: rgba(0,0,0,0.7);
    /* color: #35CBB8;
      font-family: GreycliffCF-Bold; */
      font-size: 16px;
      text-align: left;
      font-weight: bold;
      letter-spacing: 2px;	
    line-height: 19px;
    display: flex;
  }
  #blogpage .service-date,
  #blogpage .service-author {
    font-family: GreycliffCF-RegularOblique;
    font-size: 12px;
    text-align: left;
    letter-spacing: 2px;	
    line-height: 19px;
  }

  
  #blogpage .inten {
    color: #000000;
    font-family: GreycliffCF-Medium;
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 14px;
    text-align: right;
  }
  
  #blogpage .inten-range {
    color: #35CBB8;
    font-family: GreycliffCF-Bold;
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 14px;
    padding-left: 5px;
  }
  
  /* #blogpage .content-block {
    margin-right: 100px;
  } */
  
  #blogpage .service-key {
    opacity: 0.6;	
    color: #000000;	
    font-family: GreycliffCF-Medium;	
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.5px;
    line-height: 14px;
    margin-bottom: 15px;
    text-transform: capitalize;
  }
  
  #blogpage .service-desc {
    font-family: OpenSans-Light;
    color: rgba(0,0,0,0.6);
      /* color: #000000;	
      font-family: GreycliffCF-Regular;	 */
       /* To change the font to open sans */
      font-size: 16px;	 
      font-weight: 300;	
      letter-spacing: 0.3px;	
      line-height: 22px;
      /* width: 700px; */
      width: 100%;
  }
  
  #blogpage .read-more {
    color: #35CBB8;
    font-family: GreycliffCF-Medium;
    font-size: 12px;
    cursor: pointer;
    margin-left: 10px;
    letter-spacing: 0.5px;
    line-height: 13px;
    border-bottom: 1px solid #35CBB8;
  }
  
  #blogpage .book-btn {
    margin-top: 20px !important;
    /* border: 1px solid #35CBB8; */
    border: 1px solid #D6B981;;;
    height: 40px !important;
    width: 120px !important;
    /* background-color:  #35CBB8; */
    background-color:#D6B981;;;
    color: #FFFFFF !important;
    text-transform: uppercase;
    font-family: GreycliffCF-ExtraBold;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .7s;
    margin-left: 120px;
    cursor: pointer;
  }
  
  #blogpage .book-btn:hover {
    background-color: #FFFFFF;
    /* color: #35CBB8; */
    color: #D6B981 !important;
  }
  #blogpage .desc-block {
    display: flex;
  }
  #blogpage .read-active {
   height: auto !important;
    /* display: inline !important; */
  }

  
  #blogpage .booknow-btn {
      /* margin-top: 20px !important; */
  }
  
  #blogpage .booknow-btn {
    /* margin-top: 20px !important; */
    border: 1px solid #35CBB8;
    height: 44px !important;
    width: 150px !important;
    font-size: 14px;
  }
  
  #blogpages .booknow-btn:hover {
    box-shadow: none;
    /* border: 1px solid #35CBB8; */
  }
  
  #blogpage .serv-cost {
    color: rgba(0,0,0,0.8);
    font-family: Prata-Regular;
    font-size: 11px;
    letter-spacing: 2px;
    line-height: 17px;
    margin-top: 11px;
      display: flex;
      flex-direction: column;
  }

  
  #blogpage .serv-btn-outer {
      height: 54px;
      width: 180px;
      background-color: #35CBB8;
      color: #FBFBFB;
      font-family: GreycliffCF-ExtraBold;
      font-size: 16px;
      letter-spacing: 0.89px;
      line-height: 19px;
      text-align: center;
      box-shadow: 0 2px 50px 0 rgba(0,0,0,0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      cursor: pointer;
      margin-top: 15px;
  }
  
  #blogpage .serv-btn-outer:hover {
      background-color: #FFFFFF;
      color: #D6B981;
      /* color: #35CBB8; */
  }
  
  #blogpage .block {
      /* margin: 0 2.5VW 0 3.208333333333334VW; */
      /* display: flex;
      justify-content: center;
      align-content: center; */
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }
  
  
  
  #blogpage .block:last-child {
      border-bottom: none;
  }
  #blogpage .head-title {
    color: #00D0BA;
    font-family: "blueliquidbold-webfont";
    text-align: center;
    font-size: 22px;
    letter-spacing: 2px;
    line-height: 66px;
  }
  
 .blog-desc p{
    font-family: OpenSans-Light;
    font-size: 16px;
    color: rgba(0,0,0,0.6);
    letter-spacing: 0.2px;
    line-height: 20px;
  }