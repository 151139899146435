#onlinegift-container .gift-card-container {
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  margin-bottom: 30px;
  padding:0 15%;
}

#onlinegift-container .gift-card-panel {
  position: relative;
  margin: 0 12px;
}

#onlinegift-container .recipientblock {
  padding-top: 1.3020833333333335VW;
}

#onlinegift-container .gift-card-container label {
  margin:0px !important;
}

#onlinegift-container .gift-card-container .img-thumbnail {
  width: 17.541666666666666VW;
  height: 13.375VW;
  display: inline-block;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(227,227,227,1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(227,227,227,1);
  box-shadow: 0px 0px 15px 0px rgba(227,227,227,1);
}

#onlinegift-container .plan-outer .img-radio {
  position: absolute;
  opacity: 0;
}

#onlinegift-container .img-radio:checked + .img-parent {
  display: inline-block;
  border:2px solid #D5B981;
  transform: translate3d(-10px,-10px,0px);  
}

#onlinegift-container .img-radio:checked +  .img-parent img.img-thumbnail {
  transform: translate3d(10px,10px,0px);
  transition: all 0.5s;
  /* border: 2px solid red */
}

#onlinegift-container  .plan-inner {
  position: relative;  
  /* width: 250px; */
  z-index: 99;
}

#onlinegift-container .plan-outer {
  display: inline-block;
  margin: 20px 10px;
  padding: 0 10px;	
  /* width: 240px; */
}
/* #onlinegift-container  .plan-inner::after{
  position: absolute;
  top: 0;
  left: 10px;
  right: 0;
  content: '';
  height: 0;
  width: 0;
  opacity: 0;
  border: solid 1px #ffffff;
  border-top-color:  #D6B981;   
  border-right-color: #D6B981 ; 
  border-left-color:  #0eb7da 0px; 
  border-bottom-color: #0eb7da 0px ;
  z-index: -1;
  opacity: 1;
 }  

 #onlinegift-container .plan-inner:hover::before,
 #onlinegift-container .plan-inner:hover::after {
  width: 100%;
  height: 100%;
  top: -12px;
  right: -12px;
  opacity: 1;
 }
 
 #onlinegift-container .plan-inner:hover::after {  
  border-top-color:  #D6B981; 
  border-right-color: #D6B981 ;
  transition:  width 0.50s ease-out 0s, height 1s ease-out .5s;
  /* transition: height 1s ease-in-out , width 1s ease-in-out; 
} */



#onlinegift-container .form-field-price { border: none !important; }

#onlinegift-container .input-rupee-left { position: relative; }

#onlinegift-container .input-rupee-left::before {
  position: absolute;
  top: 13px;
  left:0.6525VW;
  content:"₹";
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0,0,0,0.6);
}

#overlay .input-container .input-groups .amount-price {
  width: 16.529166666666664VW !important;  
  padding: 12px 19px !important;
}