#privacypolicy  .container {
    display: flex;
    justify-content:center;
    max-width: 1300px;
    padding: 0 10.416666666666668VW !important;
}

#privacypolicy .term-blk {
    font-family: 'OpenSans-Regular';
    color: rgba(0, 0, 0, 1);
    padding-top: 130px;
    text-align: justify;  
    margin-bottom: 30px;
}

#privacypolicy .list{
    padding-left: 5%;
}

#privacypolicy .term-blk h3{
    font-family: Prata-Regular;
    color: #481D0C;
    letter-spacing: 4px;
    text-align: center;
}

#privacypolicy .term-blk p{
    font-family: 'OpenSans-Regular';
    font-size: 16px;
    color: rgba(0,0,0,0.6);
    letter-spacing: 0.2px;
    line-height: 22px;
}