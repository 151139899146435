@media screen and (min-width: 320px) and (max-width: 767px) {
  #banner-blk { 
  background-size: cover !important;
  background-position: left !important; 
  }
  #banner-blk .content-desc { width: auto; }

  #banner-blk .banner-content {
    padding: 20px;
    height: 100vh;
    background: rgba(255,255,255,0.4)
  }

  #banner-blk .content-title { font-size: 20px; line-height: 40px; }
  #banner-blk .social-media { display: none; }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #banner-blk { 
  background-size: auto 100% !important;
  background-position: unset !important; }
}
