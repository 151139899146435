/*Booking overlay Css*/
#booking-container {

}

/*radio button dynamic append card field css*/
#booking-container .membership_card_sec {
  margin-bottom: 20px;
  padding:0px 1%;
}

#booking-container .mem-card { width: 19.229166666666664VW !important; }
#booking-container .membership_card_sec .input-groups{
  display:block !important;
}
#booking-container .membership_card_sec .input-groups .field-caption {
  width: 31%;
}
/*radio button dynamic append card field css*/
.membership_card_sec {
  margin-bottom: 20px;
  padding:0px !important;
}
.membership_card_sec .input-groups .field-caption{
  width: 31%;
}

/* Dynamic service section */
#booking-container .service-container {
  margin-top:30px;
}

#booking-container .dynamic-service-sec {
  margin-bottom:20px;
}

#booking-container .person-heading {
  position: relative;
  margin: 2.604166666666667VW 0 1.5625VW 0px;
}

#booking-container .person-heading label {
  position: absolute;
  top: -13px;
  left: 0px;
  background-color: #FFFFFF;
  padding:4px 15px 4px 0px;
}

#booking-container .person-heading span {
  color: #000000;
  font-family: OpenSans-Bold;
  font-size: 14px;
  letter-spacing: 1.75px;
  line-height: 19px;
  opacity: 0.6;
}

#booking-container .service-close-icon {
  right: 0px;
  padding: 6px 0 10px 25px !important;
  left: auto !important;
  top: -21px !important;
  cursor: pointer;
}

#booking-container .dynamic-service-sec:first-child .service-close-icon {
  display: none !important;
}

#booking-container .service-close-icon span {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url("/assets/images/sprint_aroma_icons.svg");
  background-position: -541px -15px;
}
/* #booking-container .person-heading hr:first-child {
  width: 90%;
} */
#booking-container .person-heading hr {
  width: 100%;
  margin-right:5%;
  border:1px solid #D6B981;
}

#booking-container .add-service-sec {
  margin-bottom: 2%;
  text-align: right;
  /* display: none; */
}

#booking-container .add-service-btn:focus {
  outline: none;
}

#booking-container .add-service-btn {
  border: 1px solid #D6B981;
  padding:10px 25px;
  cursor: pointer;
  background: #FFFFFF;
  color: #D6B981;
  font-family: OpenSans-Bold;
  font-size: 14px;
  letter-spacing: 1.75px;
  line-height: 19px;
  text-align: right;
}

#booking-container .add-service-btn .plus-icon {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url("/assets/images/sprint_aroma_icons.svg");
  background-repeat: no-repeat;
  background-position: -686px -15px;
  margin-top: 3px;
  margin-right: 5px;
}

#booking-container .tot-book-price {
  color: #000000;
  font-family: OpenSans-SemiBold;
  font-size: 16px;
  letter-spacing: 1.75px;
  line-height: 19px;
  opacity: 0.6;
  text-align: right;
  padding-bottom: 40px;
}

#booking-container .tot-book-price span {
  font-family: OpenSans-Bold;
  font-size: 18px;
  padding-left: 10px;
}


/* .react-datepicker__input-container .react-datepicker-ignore-onclickoutside {
  width: 18.229166666666664VW !important;
  border: none;
} */


.form-control {
  width: 18.229166666666664VW;
  border: none;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0,0,0, 0.6);
} 

.react-datepicker__input-container input {
  width: 18.229166666666664VW;
  padding: 12px 10px;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0,0,0, 0.6);
  border: 1px solid #e3e3e3;
  /* background-color: #ffffff; */
  -webkit-border-radius: 2px;
  border-radius: 2px;
  z-index: 0;
}

#booking-container .price {
  width: 19.50625VW;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  opacity: 0.6;
}
#booking-container .show-to-pc { display: block; }
#booking-container .show-to-mob { display: none; }

#booking-container .addon { width: 50%; margin: auto;}
#booking-container .hide { display: none;}

@media screen and (min-width: 1600px) {
.react-datepicker__input-container input {
  width: 18.829166666666664VW;
}
}
.disclaimer{
  color: rgba(0,0,0, 0.6);
  margin-top: 1%;
  width: 100%;
}
.disclaimer-contactDetails-content,
.service-container-footer{
  display: flex;
  font-family: OpenSans-SemiBold;
  font-size: 14px;
}
.disclaimer-contactDetails{
  font-size: 10px ;
  margin-top: 2%;
}
.disclaimer-branchDetails{
  padding: 10px 0;
  font-size: 10px ;
}
.disclaimer-contactDetails-content{
  justify-content: space-between;
  flex-wrap: wrap;
}
.branchLocation, .disclaimer-contactDetails-header, .disclaimer-header{
  font-family: OpenSans-Bold;
}
.disclaimer-header {
  padding: 10px 0;
  font-size: 12px;
}

.disclaimer-content ol {
  font-size: 12px;
}
.service-lineBreak{
  /* border: 1px solid #e3e3e3 !important; */
  border: none !important;
  background-color: #e3e3e3;
  height: 1px;
}
@media screen and (min-width: 320px) and (max-width: 767px){
  .disclaimer-branchDetails{
    padding: 2.5%;
    flex: 45%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px){
  .disclaimer-branchDetails{
    padding: 2%;
    flex: 29%;
  }
}