.address-container {
  padding:10px;
}
.address-block{
  position: absolute;
  top: 0;
}
.address-block h6 {
  color: #000000;
  font-family: Prata;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 13px;
  margin: 30px 0px;
  max-height: 10px;
}
.address-block address {
  color: #000000;
  font-family: OpenSans-Light;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  font-style: normal;
  margin-bottom: 10px;
  min-height: 50px;
}

.city-address {
  width:25%;
  display: inline-block;
  position: relative;
  min-height: 200px;
  padding: 10px 30px;
}
.address-block .contact-details {
  font-family: OpenSans-Light;
  font-size: 14px;
  line-height: 22px;
  font-style: normal;
  display: block;
  line-height: 27px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
.address-block .contact-details span{
  opacity: 0.6;
}

.lower-case { text-transform: lowercase !important; }

.lower-case span { text-transform: capitalize !important; }
