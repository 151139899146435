/* Mobile responsive design */
@media screen and (min-width: 320px) and (max-width: 767px) {
  #header .header { animation-name: none !important}
  #header .menu-logo { 
    display: block !important; 
    animation-name: none; 
    padding-left: 50px; 
  }
  #header .menu-item { margin: 30px 0; }
  #header .menu-item::after { width: 100%; bottom: -8px; }
  #header .header-row { padding: 20px 20px 10px; }
  #header .header-right { position: absolute; right: 20px; }
  #header .min-header { 
    animation-name: none !important;
    padding: 10px 20px 10px !important;
  }
  #header .aroma-logo { width: 120px !important; height: auto; }
  #header .menu-row-block li { display: none; }
  #header .header-row .menu-row-block li:last-child,
  #header .min-header .menu-row-block li:last-child { height: 30px; left: 20px; }
}

/* Tablet/IPad responsive */
@media screen and (min-width: 768px) and (max-width: 1200px) {
  #header .header-row { padding: 2.125VW 40px 0; }
  #header .min-header { padding: 10px 40px !important; }
  #header .menu-row-block li { display: none; }
  #header .menu-logo { 
    display: block !important; 
    animation-name: none; 
    padding-left: 50px; 
  }
}


